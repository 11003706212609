<template>
  <section id="newsletter" class="bg-white">
    <div class="container py-12 text-center lg:py-16">
      <h2
        class="inline text-3xl font-extrabold tracking-tight text-gray-900 sm:block sm:text-4xl"
      >
        {{ $t("common.newsletter_title") }}
      </h2>
      <p
        class="inline text-2xl font-extrabold tracking-tight text-primary-600 sm:block sm:text-3xl"
      >
        {{ $t("common.newsletter_line1") }}
      </p>
      <form
        id="newsletter_form"
        class="mt-8 w-full max-w-[500px] mx-auto flex items-center justify-center flex-col"
        @submit.prevent="onSubmit"
      >
        <div class="flex w-full mt-1 rounded shadow-sm">
          <div
            class="relative flex items-center justify-center flex-grow focus-within:z-10"
          >
            <input
              id="newsletter_email"
              v-model="email"
              name="email"
              type="email"
              data-cons-subject="email"
              autocomplete="email"
              required
              :disabled="iSsending"
              class="block w-full py-3 border-gray-300 rounded-none focus:ring-primary-500 focus:border-primary-500 rounded-s lg:min-w-full rtl:rounded-r-md ltr:rounded-l-md"
              :placeholder="$t('common.email')"
              :class="store.locale == 'ar' ? 'directionrtl' : ''"
            />
            <Btn
              id="newsletter-submit"
              type="submit"
              :disabled="iSsending"
              size="xl"
              color="primary"
              class="-ms-px h-[50px] ltr:rounded-r-md ltr:rounded-l-none rtl:rounded-l-md rtl:rounded-r-none"
            >
              {{ $t("common.submit") }}
            </Btn>
          </div>
        </div>

        <label
          class="flex items-center justify-center w-full p-2 border border-transparent cursor-pointer terms_link"
          :class="{
            'border-red-500 bg-red-50 text-red-400':
              errorsObj && errorsObj.accept_terms,
          }"
        >
          <input
            id="newsletter_tos"
            v-model="accept_terms"
            type="checkbox"
            required
            data-cons-preference="terms-and-conditions"
            class="me-3"
          />
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="terms"
            v-html="
              $t('common.i_agree_with_the_terms_and_conditions').replace(
                'legal',
                $i18nPath('/terms')
              )
            "
          />
          <!-- eslint-enable -->
        </label>

        <input
          type="hidden"
          name="newsletter"
          value="newsletter"
          data-cons-preference="newsletter"
        />

        <ErrorBag :errors="errorsObj" class="mt-4" />

        <div v-if="VueHcaptcha" class="bg-gray-50">
          <component
            :is="VueHcaptcha"
            ref="invisibleHcaptcha"
            size="invisible"
            :sitekey="$config.HCAPTCHA_SITE_KEY"
            theme="dark"
            :language="store.locale"
            @verify="onVerify"
            @expired="onExpire"
            @closed="onExpire"
            @challengeExpired="onExpire"
            @error="onError"
          />
        </div>
      </form>
    </div>
  </section>
</template>

<script setup>
// import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";/
import { useMainStore } from "@/store/index";
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();
const nuxtApp = useNuxtApp();
const store = useMainStore();

let VueHcaptcha = shallowRef(null);
const email = ref(null);
const accept_terms = ref(false);
const iSsending = ref(false);
const invisibleHcaptcha = ref(null);
let errorsObj = reactive({});
const $i18nPath = nuxtApp.$i18nPath;
const $config = nuxtApp.$config.public;

onMounted(() => {
  if (process.browser) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!VueHcaptcha.value) {
            VueHcaptcha.value = defineAsyncComponent(() =>
              import("@hcaptcha/vue3-hcaptcha")
            );
          }
        }
      });
    });

    observer.observe(document.getElementById("newsletter"));
  }
});

function onVerify(token, ekey) {
  useAPI("/api/front/subscriber", {
    method: "POST",
    body: {
      email: email.value,
      accept_terms: accept_terms.value,
      "h-captcha-response": token,
    },
  })
    .then((response) => {
      nuxtApp.$toast.success(t("common.newsletter_thanks"));
      email.value = null;
      accept_terms.value = false;
      iSsending.value = false;

      window._iub.cons_instructions.push([
        "submit",
        {
          writeOnLocalStorage: false,
          form: {
            selector: document.getElementById("newsletter_form"),
          },
          consent: {
            legal_notices: [
              {
                identifier: "privacy_policy",
              },
              {
                identifier: "cookie_policy",
              },
              {
                identifier: "term",
              },
            ],
          },
        },
        {
          success(response) {
            // console.log(response)
          },
          error(response) {
            console.warning(response);
          },
        },
      ]);
    })
    .catch((error) => {
      console.error(error);
      errorsObj = error.response.data.errors;
      iSsending.value = false;
      accept_terms.value = false;
    });
}

function onExpire() {
  // this.$toast.error('Captcha expired')
  console.log("[vue-htcapcha] Expired token");
  iSsending.value = false;
}

function onError() {
  console.log("[vue-htcapcha] Failed to load hcaptcha");
  iSsending.value = false;
}

function onSubmit() {
  if (iSsending.value) {
    return;
  }
  iSsending.value = true;
  errorsObj = {};

  if (invisibleHcaptcha.value) {
    invisibleHcaptcha.value.execute();
  }
}
</script>

<style lang="postcss">
.directionltr {
  direction: ltr;
}
.directionrtl {
  direction: rtl;
}
#newsletter_form .terms a {
  @apply text-primary;
  &:hover {
    @apply underline;
  }
}
</style>
